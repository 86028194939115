import { FunctionComponent } from 'react';
import BarChart, { BarChartProps } from './BarChart';
import {CompensationChartData} from 'hooks/useApi';
import {BarChartData} from 'shared/interfaces';

export type PensionerConversionsBarChartProps = BarChartProps;

/**
 * Combines Arbeitgeberbeitrag and Matchingbeitrag.
 */
const prepareChartData = (data: CompensationChartData[]): BarChartData[] => {
  return data.map((entry) => ({
    x: entry.x,
    y0: entry.y0,
    y1: (entry.y1 ?? 0) + (entry.y2 ?? 0),
  }));
}

const PensionerConversionsBarChart: FunctionComponent<PensionerConversionsBarChartProps> = (props) => <BarChart
  {...props}
  data={prepareChartData(props.data)}
/>

export default PensionerConversionsBarChart