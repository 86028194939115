import { DisclaimerMessage } from 'components/Disclaimer'
import useApi, { QueryKey } from 'hooks/useApi'
import { useUserRecord } from 'hooks/useUserRecord'
import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import routesDictionary from 'routes'
import { BarChartDataType } from 'shared/components/BarChart'
import { useRouteHelper } from 'shared/hooks/useRouteHelper'
import { useBackButtonPath, useViewTitle } from 'state/useNavigationState'
import PensionerConversionsBarChart from 'shared/components/PensionerConversionsBarChart'

const PensionerConversionsAllYears: React.FC = () => {
	const { getChildPath, navigateTo } = useRouteHelper()
	const { t } = useTranslation()

	useViewTitle(t('view.pensionerConversions.conversionOverview'))
	useBackButtonPath(undefined)

	const { userId } = useUserRecord()

	const api = useApi()
	const { data, status } = useQuery(
		[
			QueryKey.allCompensations,
			{
				userId,
			},
		],
		api.getCompensation,
		{
			enabled: !!userId,
		}
	)

	const onClick = (year: number) => {
		// Math.round is needed, as somehow the cached values are sometimes converted to a float
		navigateTo(
			`${getChildPath(routesDictionary.pensioner, 'conversions', {
				main: [userId!],
			})}/${Math.round(year)}`
		)
	}

	return (
		<div className={`visually-hidden visually-hidden--${String(status !== 'success')}`}>
			{'success' === status && !data?.length && (
				<DisclaimerMessage text={<Trans i18nKey="view.pensionerConversions.noDataDisclaimer" />} />
			)}

			{data && 0 !== data.length && (
				<PensionerConversionsBarChart
					className="conversions__chart"
					data={data}
					yDataType={BarChartDataType.currency}
					xDataType={BarChartDataType.string}
					panToEnd={true}
					clickHandler={onClick}
					legend={[
						t('view.pensionerConversions.legend.ownContribution'),
						t('view.pensionerConversions.legend.totalInclMatching'),
					]}
				/>
			)}
		</div>
	)
}

export default PensionerConversionsAllYears
